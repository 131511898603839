@tailwind base;
@tailwind components;
@tailwind utilities;

.wallet {
  border-left-width: 6px;
}

.wallet.loading {
  border-color: #b8c2cc;
}

.wallet.empty {
  border-color: #ef5753;
}

.wallet.used {
  border-color: #ffed4a;
}

.wallet.filled {
  border-color: #51d88a;
}

.filled {
  color: #51d88a;
}
